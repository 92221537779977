import React from "react";
import { MetaTags } from "../components/MetaTags";
import { Layout } from "../components/layout/Layout";
import { WasalineHero } from "../components/sections/insight/Wasaline/WasalineHero";
import { WasalineArticle } from "../components/sections/insight/Wasaline/WasalineArticle";

const WasalinePage: React.FC = () => {
  return (
    <Layout
      colorScheme="secondary"
      heroSection={<WasalineHero />}
      isHeroShortBaseOnImage
      isWhite
    >
      <WasalineArticle />
    </Layout>
  );
};

export default WasalinePage;

export const Head = () => <MetaTags title="Wasaline" />;